<template>
  <div class="position-relative">
    <Swiper :id="`post-slider-homeSlider-landing`" class="slider-homeSlider-landing"  :options="swiperOptions" :pagination="true" :navigation="true" >
      <swiperSlide v-for="(image, key1) in swiperImages" :key="key1">
        <section
          :style="{
            'background-size': 'cover',
            'background-position': 'center',
            'background-image':
            'url(' + image + ')'
          }"
          class="w-100 h-100 position-relative">
        </section>
      </swiperSlide>
    </Swiper>
    <div class="arrows">
      <span class="swiper-button-prev landing-swiper-button-prev swiper-button-no-rotate top-50 position-absolute z-2 ">
        <!-- <i class="las la-arrow-circle-left"></i> -->
        <img :src="require('@/assets/images/ibbil/icon-arrow.svg')" />
      </span>
      <span class="swiper-button-next landing-swiper-button-next top-50 position-absolute z-2 ">
        <img :src="require('@/assets/images/ibbil/icon-arrow.svg')" />
      </span>

    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      swiperOptions: {
        spaceBetween: 1,
        autoplay: false,
        slidesPerView: 1,
        pagination: {
          clickable: true,
          el: '.slider-homeSlider-landing .swiper-pagination',
          type: 'bullets'
        },
        // Navigation arrows
        navigation: {
          nextEl: '.landing-swiper-button-next',
          prevEl: '.landing-swiper-button-prev'
        },
        effect: 'fade',
        fadeEffect: { crossFade: true }
      },
      swiperImages: [
        require('@/assets/images/ibbil/homePage/cover3.jpeg'),
        require('@/assets/images/ibbil/homePage/cover2.jpeg'),
        require('@/assets/images/ibbil/homePage/cover1.jpeg'),
        require('@/assets/images/ibbil/homePage/cover4.jpeg')
      ]
    }
  },
  computed: {
    // siteLang () {
    //   return this.$store.getters['Setting/langState'].value
    // }
  },
  watch: {
    // siteLang (currentLang) {
    //   this.reverseSwiperBtns(currentLang)
    // }
  },
  methods: {
    // reverseSwiperBtns (lang) {
    //   if (lang === 'ar') {
    //     this.swiperOptions.navigation.nextEl = '.swiper-button-prev'
    //     this.swiperOptions.navigation.prevEl = '.swiper-button-next'
    //   } else {
    //     this.swiperOptions.navigation.nextEl = '.swiper-button-next'
    //     this.swiperOptions.navigation.prevEl = '.swiper-button-prev'
    //   }
    // }
  },
  mounted () {
    // this.reverseSwiperBtns(this.siteLang)
  }
}
</script>
<style>
.swiper-container-post-slider-homeSlider-landing {
  height: 410px;
}
.swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #fff;
  margin: 0 3px;
  border-radius: 50%;
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  background-color: var(--iq-primary);
}
.swiper-button-next{
  cursor: pointer;
  left: 50px;
}
.swiper-button-prev {
  right: 50px;
}
.swiper-button-next img {
  transform: rotateY(180deg);
}
.swiper-button-prev.swiper-button-no-rotate img {
  transform: rotateY(0) !important;
}
.arrows img {
  width: 35px;
}
</style>
