<template>
  <div class="bg-primary pt-5 pb-0 mb-500">
    <b-container>
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h2 class="font-size-21 font-weight-bold text-white ibbil-vertical-light-line position-relative">{{ $t('endUser.news') }}</h2>
        <div class="justify-left">
          <button class="bg-transparent border border-warning text-white font-bold py-2 px-4 iq-border-radius-5">
            <router-link :to="{name: 'mediaCenter'}" tag="span">{{$t('endUser.seeAll')}}</router-link>
          </button>
        </div>
<!--        {{allNews}}-->
      </div>
      <b-row>
        <b-col md="6" lg="3" class="mt-30" v-for="(i, key) in allNews.slice(-4)" :key="key">
          <div class="transition-top-card position-relative">
            <div class="top-card rounded-top overflow-hidden">
              <section :style="{
              'background-size': 'cover',
              'background-position': 'center',
              'background-image':
           'url(' + i.featured_image + ')' }"
                       class="w-100 h-250px position-relative">
                <div class="overlay-card"></div>
              </section>
<!--              <img class="w-100" :src='i.image' :alt="i.name"/>-->
              <div class="bg-white position-absolute py-2 bottom-transition-card px-3 w-100">
                  <span class="position-absolute bg-warning text-white p-1  position-absolute tag-card" v-if="i.category">{{i.category.title}}</span>
              <span class=" d-block text-gray text-center m-0 font-weight-bold my-2">{{i.created_at}}</span>
              <h4 class="text-primary font-weight-bold font-size-16 text-center">{{i.title.length >= 20 ? i.title.slice(0,20) + '...' : i.title}}</h4>
                <div  class="bottom-card bg-white">
                  <p style="" class="text-black mt-2 m-0 font-size-14 text-center">{{i.content.length >= 200 ? i.content.slice(0,200) + '...' : i.content}}</p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import allNews from '@/FackApi/json/news.json'
import homeServices from '@/modules/primaryPages/home/services/home'

export default {
  data () {
    return {
      news: allNews,
      tags: 'خبر',
      allNews: []
    }
  },
  methods: {
    getNews () {
      homeServices.getAllNews().then(res => {
        this.allNews = res.data.data
      })
    }
  },
  created () {
    this.getNews()
  }
}
</script>
<style lang="scss">
.transition-top-card {
  cursor: pointer;
}
.transition-top-card:hover .bottom-transition-card {
  transform: translateY(50%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.transition-top-card:hover .overlay-card {
  position: absolute;
  background: rgb(43 88 66 / 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.transition-top-card:hover .bottom-transition-card p {
  font-size: initial;
  height: initial;
  visibility: visible;
}
.bottom-transition-card {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 /
    0.1);
  bottom: 0;
  transition: 0.4s linear;
  transform: translateY(100%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.bottom-transition-card p {
  height: 0;
  visibility: hidden;
}
.mb-500 {
  margin-bottom: 150px !important;
}
.tag-card {
  border-radius: 5px;
  top: -50px;
  right: 10px;
}
@media (max-width: 768px) {
  .mt-30 {
    margin-top: 120px;
  }
  .mt-30:first-of-type {
    margin-top: 0px;
  }
}
@media(min-width:768px) and (max-width: 992px) {
  .mt-30 {
    margin-top: 120px;
  }
  .mt-30:first-of-type, .mt-30:nth-of-type(2)  {
    margin-top: 0px;
  }
}
</style>
