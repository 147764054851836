<template>
  <div class="py-5">
    <b-container>
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h2 class="font-size-21 font-weight-bold text-black ibbil-vertical-dark-line position-relative">{{ $t('endUser.services') }}</h2>
        <div class="justify-left">
          <button
              class="bg-transparent border border-warning text-black font-bold py-2 px-4 iq-border-radius-5 "
          >
            <router-link :to="{name: 'services'}" tag="span">{{$t('endUser.seeAll')}}</router-link>
          </button>
        </div>
      </div>
      <b-row>
        <b-col class="mb-4" md="3" v-for="(service, key) in allServices" :key="key">
          <b-card>
            <h3>{{service.header}}</h3>
            <p class="min-h-100">{{service.address}}</p>
            <div class="d-flex justify-content-between align-items-center">
              <button
                  class="bg-transparent border border-black text-black font-bold py-2 px-4 iq-border-radius-5 "
              >
                <router-link :to="{name: service.routeName}" tag="span">{{ $t('endUser.seeAll') }}</router-link>
              </button>
              <img :src="service.image">
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  // data () {
  //   return {
  //     allServices: [
  //       {
  //         header: this.$t('endUser.permissions'),
  //         address: this.$t('endUser.permissionsInfo'),
  //         routeName: 'permissions',
  //         image: require('@/assets/images/ibbil/permits1.svg')
  //       },
  //       {
  //         header: this.$t('endUser.insurance'),
  //         address: this.$t('endUSer.insuranceInfo'),
  //         routeName: 'insurance',
  //         image: require('@/assets/images/ibbil/Insureance1.svg')
  //       },
  //       {
  //         header: this.$t('endUser.transport'),
  //         address: this.$t('endUser.transportInfo'),
  //         routeName: 'transportation',
  //         image: require('@/assets/images/ibbil/turck1.svg')
  //       },
  //       {
  //         header: this.$t('endUser.veterinarian'),
  //         address: this.$t('endUser.veterinaryInfo'),
  //         routeName: 'veterinarian',
  //         image: require('@/assets/images/ibbil/veterinarian.svg')
  //       }
  //     ]
  //   }
  // },
  computed: {
    allServices () {
      return [
        {
          header: this.$t('endUser.permissions'),
          address: this.$t('endUser.permissionsInfo'),
          routeName: 'permissions',
          image: require('@/assets/images/ibbil/permits1.svg')
        },
        {
          header: this.$t('endUser.insurance'),
          address: this.$t('endUser.insuranceInfo'),
          routeName: 'insurance',
          image: require('@/assets/images/ibbil/Insureance1.svg')
        },
        {
          header: this.$t('endUser.transport'),
          address: this.$t('endUser.transportInfo'),
          routeName: 'transportation',
          image: require('@/assets/images/ibbil/turck1.svg')
        },
        {
          header: this.$t('endUser.veterinarian'),
          address: this.$t('endUser.veterinaryInfo'),
          routeName: 'veterinarian',
          image: require('@/assets/images/ibbil/veterinarian.svg')
        }
      ]
    }
  }
}
</script>
<style>
.min-h-100 {
  height: 100px;
}
</style>
