<template>
  <div class="p-5">
    <b-container>
      <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">
        {{ $t('endUser.statistics') }}
      </h2>
      <b-row>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">100</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.InsurancePolicies') }}</h3>
          </div>
        </b-col>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">220</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.purchases') }}</h3>
          </div>
        </b-col>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">150</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.transportationsProcess') }}</h3>
          </div>
        </b-col>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">50</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.merchants') }}</h3>
          </div>
        </b-col>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">300</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.productsCount') }}</h3>
          </div>
        </b-col>
        <b-col md="2" class="mb-5">
          <div class="d-flex flex-column text-primary font-size-12 text-center">
            <h2 class="text-primary font-weight-bold font-size-35">70</h2>
            <h3 class="text-primary font-weight-bold font-size-34">{{ $t('endUser.doctorsCount') }}</h3>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
