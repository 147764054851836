<template>
  <div class="new-stores py-5" >
    <b-container>
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h2
          class="font-size-21 font-weight-bold text-black ibbil-vertical-dark-line position-relative"
        >
          {{ $t('marketplace.LatestStores') }}
        </h2>
        <div class="justify-left">
          <button
            class="bg-transparent border border-warning text-black font-bold py-2 px-4 iq-border-radius-5"
          >
            <router-link :to="{name: 'stores'}" tag="span">{{ $t('endUser.seeAll') }}</router-link>
          </button>
        </div>
      </div>
      <b-row>
        <b-col v-for="(store, key) in newStores" :key="key" class="mb-4" md="6" lg="3">
            <store-card
            :storeItem = "store"
            />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import StoreCard from './store-card.vue'
import marketPlace from '@/modules/primaryPages/home/services/home'
export default {
  components: { StoreCard },
  data () {
    return {
      newStores: []
    }
  },
  methods: {
    getNewStores () {
      marketPlace.getNewStores().then(res => {
        this.newStores = res.data.data
      })
    }
  },
  created () {
    this.getNewStores()
  }
}
</script>
<style lang="scss">
.new-stores{
  @media (min-width: 992px) {
    height: 520px;
  }
}
.h-64 {
  height: 64rem;
}

</style>
