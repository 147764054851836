<template>
  <div @click="gotToStoreDetails(storeItem)">
  <div class="store-card rounded cursor-pointer">
    <section
      :style="{
        'background-size': 'cover',
        'background-position': 'center',
       'background-image': 'url(' + storeItem.cover + ')'
      }"
      class="card-banner position-relative w-100 h-160px"
    ></section>
    <div class="storeInfo bg-white p-3 position-relative min-h-150">
      <div class="position-absolute logoStoreCard">
        <div class="logoCard bg-white shadow rounded-circle">
          <img
            class="img-fluid"
            :src="storeItem.logo"
            :alt="storeItem.name"
          />
        </div>
      </div>
      <div class="storeContentInfo text-center">
        <h5 class="name-of-store font-weight-bold">{{ storeItem.name.length > 12 ? storeItem.name.slice(0, 12) + '...' : storeItem.name }}</h5>
        <div class="hovered-content pb-2 position-absolute">
          <div class="container">
            <p class="text-muted">
              {{ storeItem.description.length > 70 ? storeItem.description.slice(0, 70) : storeItem.description }}
            </p>
            <div
              class="action-btns text-muted d-flex align-items-center justify-content-between"
            >
              <div class="action d-flex align-items-center">
                <span class="count-number">{{ storeItem.total_comments }}</span>
                <i class="lar la-comment"></i>
              </div>
              <div class="action d-flex align-items-center">
                <span class="count-number">{{ storeItem.total_views }}</span>
                <i class="las la-eye"></i>
              </div>
              <div class="action d-flex align-items-center">
                <span class="count-number">{{storeItem.total_likes}}</span>
                <i class="las la-thumbs-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['storeItem'],
  methods: {
    gotToStoreDetails (payload) {
      this.$store.commit('cart/changeSelectedStore', payload)
      this.$router.push({ name: 'storeProfile', params: { id: payload.id, slogan: payload.url } })
    }
  }
}
</script>

<style lang="scss">
.min-h-150 {
  min-height: 160px !important;
}
.name-of-store {
  padding: 5px 0;
}
@mixin hoverEffect {
  .logoStoreCard {
    right: 10px;
    transform: translateX(6px);
    .logoCard {
      transform: scale(0.7);
    }
  }
  .storeContentInfo {
    padding-top: 0px;
    .star-icons {
      /*transform: translate(2px, -40px);*/
      transform: translate(15px, -26px)
    }
    h5 {
      color: var(--iq-primary);
    }
    .hovered-content {
      // background: #fff;
      height: initial;
      opacity: 1;
      visibility: visible;
    }
  }
}
.store-card {
  border-radius: 10px ;
  overflow: hidden;
  .logoStoreCard {
    top: -50px;
    right: 50%;
    transform: translateX(50%);
    transition: 0.3s ease-in-out;
  }
  .logoCard {
    width: 95px;
    height: 95px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }
  .storeContentInfo {
    padding-top: 50px;
    transition: 0.3s ease-in-out;
    .star-icons {
      transition: 0.3s ease-in-out;
    }
    .hovered-content {
      // position: absolute;
      width: 100%;
      // border-radius: 0 0 10px 10px;
      left: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
      .action-btns {
        .action {
          gap: 5px;
          i {
            font-size: 24px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
  .card-banner {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      transition: 0.3s ease-in-out;
    }
  }
  &:hover {
    .name-of-store {
      padding: 5px;
      transition:  0.3s ease-in-out;
    }
    .storeInfo {
      padding-top: 0 !important;
      transition:  0.3s ease-in-out;
    }
    .storeInfo h5 {
      margin-right: 10px;
    }
    .card-banner {
      &::before {
        background: rgba($color: #000000, $alpha: 0.3);
      }
    }
    @include hoverEffect;
  }
  @media (max-width: 992px) {
    @include hoverEffect;
    .storeContentInfo {
      .hovered-content {
        position: relative;
      }
    }
  }
}
</style>
