<template>
  <div class="bg-brown py-5">

      <div class="d-flex justify-content-center align-items-center mb-5">
        <h2 class="font-size-21 font-weight-bold text-white main-header-event position-relative">{{ $t('endUser.events') }}</h2>
      </div>
      <div class="position-relative overflow-hidden" v-if="allEvents.length">
        <Swiper :id="`post-slider-list-of-events`" class="slider-list-of-events"  :options="swiperOptions" :pagination="false">
          <swiperSlide class="event-slide" v-for="(i, key1) in allEvents" :key="key1">
            <section class="overflow-hidden">
              <div class="h-64 overflow-hidden">
                <div
                    class="opacity-0 position-absolute bg-warning w-100 h-100 d-flex flex-column justify-content-center text-center">
                    <span class="text-lg font-semibold block">{{i.title.length >= 20 ? i.title.slice(0, 20) + '...' : i.title}}</span>
                    <span class="text-xs block mt-2">{{i.content.length >= 20 ? i.content.slice(0, 20) + '...' : i.content}}</span>
                </div>
                <img :src="i.featured_image" class="w-100 h-100"  :alt="i.name" />
              </div>
            </section>
          </swiperSlide>
        </Swiper>
        <div class="d-flex justify-content-between w-100 top-50 px-2 position-absolute z-2 arrows">
        <span class="swiper-button-next events-swiper-button-next  top-50 position-absolute z-2">
          <img :src="require('@/assets/images/ibbil/icon-arrow-warning.svg')" />
        </span>
          <span class="swiper-button-prev events-swiper-button-prev swiper-button-no-rotate top-50 position-absolute z-2">
          <img :src="require('@/assets/images/ibbil/icon-arrow-warning.svg')" />
      </span>
        </div>
      </div>
  </div>
</template>
<script>
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  data () {
    return {
      swiperOptions: {
        centeredSlides: false,
        spaceBetween: 8,
        autoplay: false,
        slidesPerView: 5,
        pagination: {
          clickable: true,
          el: '.slider-list-of-events .swiper-pagination',
          type: 'bullets'
        },
        // Navigation arrows
        navigation: {
          nextEl: '.events-swiper-button-next',
          prevEl: '.events-swiper-button-prev'
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            centeredSlides: true
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false
          },
          992: {
            slidesPerView: 4.5,
            spaceBetween: 30,
            centeredSlides: false
          }
        }
      },
      allEvents: []
    }
  },
  methods: {
    getEvents () {
      homeServices.getAllEvents().then(res => {
        this.allEvents = res.data.data
      })
    }
  },
  created () {
    this.getEvents()
  }
}
</script>
<style lang="scss">
.swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #fff;
  margin: 0 3px;
  border-radius: 50%;
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  background-color: var(--iq-primary);
}
.swiper-button-next, .swiper-button-prev{
  cursor: pointer;
}
.swiper-button-prev img {
  transform: rotateY(180deg);
}
.arrows img {
  width: 35px;
}
.h-64 {
  height: 80rem;
}
/*#post-slider-list-of-stores .swiper-wrapper .swiper-slide  {
  display: block !important;
}*/

.event-slide{
  * {
    transition: 0.3s ease-out;
  }
  p {
    background-color: var(--iq-white);
   }
  &:hover{
    cursor: pointer;
    .opacity-0 {
      opacity: 1;
    }
  }
}
.swiper-button-disabled {
  opacity: 0.3;
}
#post-slider-list-of-events .swiper-wrapper .swiper-slide  {
  display: block !important;
}
</style>
