<template>
  <div>
    <homeSlider/>
    <aboutUsAndRegistration class="my-5"/>
    <listOfStores />
    <listOfServices />
    <events />
    <statistics />
    <listOfNews />
    <newStores />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import homeSlider from '../components/homeSlider'
import aboutUsAndRegistration from '../components/aboutUsAndRegistration'
import listOfStores from '../components/listOfStores'
import listOfServices from '../components/listOfServices'
import events from '../components/events'
import statistics from '../components/statistics'
import listOfNews from '../components/listOfNews'
import newStores from '../components/newStores/newStores.vue'
export default {
  name: 'Home',
  components: { homeSlider, aboutUsAndRegistration, listOfStores, listOfServices, events, statistics, listOfNews, newStores },
  mounted () {
    core.index()
  },
  data () {
    return {
    }
  }
}
</script>
